import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { TweenMax } from "gsap/all"
// import SplitText from "gsap/SplitText"
// import ScrollMagic from "scrollmagic";

import TeamMember from "../components/team-member"
import HeroTitle from "../components/hero-title"

// Images
import ImageHero from "../components/img/image-hero"
import Animal1 from "../components/img/animal-1"
import Animal2 from "../components/img/animal-2"
import Animal3 from "../components/img/animal-3"
import TeamEnzo from "../components/img/team-enzo"
import ImageHistory from "../components/img/image-history"
// import FloatingImages from "../components/floating-images/floating-images"


class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.nt3 = null;
    this.nt3Tween = null;
  }

  componentDidMount() {
    // var controller = new ScrollMagic.Controller();

    // const nt3_1_Split = new SplitText("#nt-3-1", {
    //   type: "lines"
    // });
    // const nt3_2_Split = new SplitText("#nt-3-2", {
    //   type: "lines"
    // });

    // new ScrollMagic.Scene({
    //   triggerElement: "#nt-3-1",
    //   offset: -200 // start this scene after scrolling for 50px
    // })
    // .on("start", function (e) {
    //   TweenMax.staggerFrom(
    //     nt3_1_Split.lines,
    //     1,
    //     {
    //       ease: 'Power2.easeOut',
    //       yPercent: 100,
    //     },
    //     0.1
    //   );
    //   TweenMax.staggerFrom(
    //     nt3_2_Split.lines,
    //     1,
    //     {
    //       ease: 'Power2.easeOut',
    //       yPercent: 100,
    //     },
    //     0.1
    //   );
    // })
    // .addTo(controller);
  }

  render() {
    return <Layout>
    <SEO title="Home" />
    {/* MAIN */}
    <div className="section hero-section">
      <div className="container hero-title-container">
        <div className="row">
          <div className="col-12 text-center">
            <HeroTitle text="Borghetto" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="separator"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <p className="nt-3" id="nt-3-1">Azienda agricola e allevamento bestiame</p>
          </div>
          <div className="col-lg-6">
            <p className="text-right nt-3" id="nt-3-2">Località borghetto — Asola (MN)</p>
          </div>
        </div>
      </div>
      <div className="container section-spacing-t">
        <div className="row">
          <div className="col-12">
            <ImageHero />
          </div>
        </div>
      </div>
    </div>

    {/* IMAGE */}
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="main-paragraph">
              Alleviamo solo bovini di altissima qualità, razza <span className="t-primary">Blonde d’Aquitaine</span>, dando la massima importanza al benessere psico-fisico dell’animale.
            </p>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 pro-points">
            <div className="pro-point">
              <div className="point-title">
                6mq
              </div>
              <div className="point-desc">
                Spazio di vita per ogni animale.
              </div>
            </div>
            <div className="pro-point">
              <div className="point-title">
                Alimentazione
              </div>
              <div className="point-desc">
                Produzione autonoma garantita.
              </div>
            </div>
          </div>
          <div className="col-lg-7 offset-lg-1">
            <p className="main-paragraph">
              Utilizziamo solo materie prime coltivate nei nostri campi per fornire un’alimentazione sana, corretta e garantita.
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* L'ANIMALE AL PRIMO POSTO */}
    {/* <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="section-title text-center">
              L'animale al primo posto
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 animal-images">
            <div className="image-desc i-1">
              <Animal1 />
              <div className="desc">
                Pulizia della stalla e cambio del letto ogni 15 giorni per garantire un alto standard di vita.
              </div>
            </div>
            <div className="animal-spacer"></div>
            <div className="image-desc i-2">
              <Animal2 />
              <div className="desc">
                Trinciato prodotto direttamente in azienda. Nessun additivo aggiunto.
              </div>
            </div>
            <div className="animal-spacer"></div>
            <div className="image-desc i-3">
              <Animal3 />
              <div className="desc">
                Proteine selezionate dal consorzio di cremona.
            </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    {/* VITA IN AZIENDA */}
    {/* <div className="section section-azienda">
      <FloatingImages />
    </div> */}

    {/* HISTORY */}
    <div className="section section-history">
      <div className="container">
        <div className="row">
          <div className="col-12 history-image">
            <div className="history-desc">Est. 1964</div>
            <ImageHistory />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="section-title">
              Un’attività tramandata<br />di generazione in generazione.
            </h2>
          </div>
        </div>
        <div className="row c-mb-5">
          <div className="col-lg-6">
            <p>
              L’azienda agricola nasce nel 1964 grazie all’intraprendenza di Enzo (detto Guglielmo) e Sergio, i due fratelli della famiglia Ruggeri.
              Partendo da qualche appezzamento di terra e una piccola stalla, negli anni ampliano l’azienda e si specializzano nell’allevamento di bestiame.
            </p>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-3 offset-lg-1">
            <TeamMember Name="Enzo (detto Guglielmo)">
              <TeamEnzo />
            </TeamMember>
          </div>
          <div className="col-2">
            <TeamMember Name="Angela">
              <TeamEnzo />
            </TeamMember>
          </div>
          <div className="col-3">
            <TeamMember Name="Sergio">
              <TeamEnzo />
            </TeamMember>
          </div>
          <div className="col-2">
            <TeamMember Name="Flavia">
              <TeamEnzo />
            </TeamMember>
          </div>
        </div> */}
        <div className="row c-my-5">
          <div className="col-lg-6 offset-lg-6 text-right">
            <p>
              Nel 1990 i figli di Enzo, Rino e Fabrizio, si prendono in carico il mantenimento dell’azienda e nel giro di pochi anni passanno da 50 a più di 300 capi allevati.
            </p>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-3 offset-lg-3">
            <TeamMember Name="Rino">
              <TeamEnzo />
            </TeamMember>
          </div>
          <div className="col-3">
            <TeamMember Name="Fabrizio">
              <TeamEnzo />
            </TeamMember>
          </div>
        </div> */}
      </div>
    </div>

    {/* CONTATTI */}
    <div className="section section-contacts">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="contacts-title">Dove siamo</p>
            <p className="main-paragraph">
              Strada Borghetto 44<br />
              Asola (MN) — 46041 <a href="https://google.com">(portami qui)</a>
            </p>
          </div>
        </div>
        <div className="row c-mt-5">
          <div className="col-12">
            <p className="contacts-title">Telefono</p>
            <p className="main-paragraph">
              0376 735056
            </p>
          </div>
        </div>
        <div className="row c-mt-5">
          <div className="col-12">
            <p className="contacts-title">Email</p>
            <p className="main-paragraph">
              info@aziendaagricolaborghetto.it
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>;
  }

}

export default IndexPage
